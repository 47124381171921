import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { withTranslation } from "react-i18next";

const url = "patient-incident/";

const PatientIncidentTable = EgretLoadable({
    loader: () => import("./PatientIncidentAnalysisTable"),
});
const ViewPatientIncidentAnalyticsTable = withTranslation()(PatientIncidentTable);

const PatientIncidentAnalytic = EgretLoadable({
    loader: () => import("./PatientIncidentAnalysisForm"),
});

const PatientIncidentAnalytics = withTranslation()(PatientIncidentAnalytic);

const PatientIncidentAnalysisRoutes = [
    {
        path: ConstantList.ROOT_PATH + url + "analytics",
        exact: true,
        component: ViewPatientIncidentAnalyticsTable,
    },
    {
        path: ConstantList.ROOT_PATH + url + "analytics/(id)?/:id?",
        exact: true,
        component: PatientIncidentAnalytics,
    },
];

export default PatientIncidentAnalysisRoutes;
