export const themeColors = {
    white: {
        palette: {
            type: "light",
            primary: {
                main: "#ffffff",
                contrastText: "rgba(0,0,0,.87)"
            },
            secondary: {
                main: "#ff9e43"
            }
        }
    },
    slateDark1: {
        palette: {
            type: "dark",
            primary: {
                main: "#222A45",
                contrastText: "#ffffff"
            },
            secondary: {
                main: "#ff9e43"
            },
            background: {
                paper: "#222A45",
                default: "#1a2038"
            }
        }
    },
    slateDark2: {
        palette: {
            type: "dark",
            primary: {
                main: "#1a2038",
                contrastText: "#ffffff"
            },
            secondary: {
                main: "#ff9e43"
            },
            background: {
                paper: "#222A45",
                default: "#1a2038"
            }
        }
    },
    purple1: {
        palette: {
            type: "light",
            primary: {
                main: "#7467ef",
                contrastText: "#ffffff"
            },
            secondary: {
                main: "#ff9e43"
            }
        }
    },
    purple2: {
        palette: {
            type: "light",
            primary: {
                main: "#6a75c9",
                contrastText: "#ffffff"
            },
            secondary: {
                main: "#ff9e43"
            }
        }
    },
    purpleDark1: {
        palette: {
            type: "dark",
            primary: {
                main: "#7467ef",
                contrastText: "#ffffff"
            },
            secondary: {
                main: "#ff9e43"
            },
            background: {
                paper: "#222A45",
                default: "#1a2038"
            }
        }
    },
    purpleDark2: {
        palette: {
            type: "dark",
            primary: {
                main: "#6a75c9",
                contrastText: "#ffffff"
            },
            secondary: {
                main: "#ff9e43"
            },
            background: {
                paper: "#222A45",
                default: "#1a2038"
            }
        }
    },
    blue: {
        palette: {
            type: "light",
            primary: {
                main: "#3366FF",
                contrastText: "#ffffff"
            },
            secondary: {
                main: "#FFAF38",
                contrastText: "#ffffff"
            }
        }
    },
    blueDark: {
        palette: {
            type: "dark",
            primary: {
                main: "#3366FF",
                contrastText: "#ffffff"
            },
            secondary: {
                main: "#FF4F30",
                contrastText: "#ffffff"
            },
            background: {
                paper: "#222A45",
                default: "#1a2038"
            }
        }
    },

    globitsBlueDark: {
        palette: {
            type: "dark",
            primary: {
                main: "#1e2635",
                contrastText: "#ffffff"
            },
            secondary: {
                main: "#f9be75",
                contrastText: "#ffffff"
            },
            background: {
                paper: "#222A45",
                default: "#1a2038"
            }
        }
    },
};